import {
  BulkRequest,
  BulkResponse,
  GetActiveFeaturesResponse,
  ServicesCatalogServer,
  GetServiceResponse,
  Rate,
} from '@wix/ambassador-services-catalog-server/http';
import {
  CalendarServer,
  ListSlotsResponse,
  ListSlotsRequest,
} from '@wix/ambassador-calendar-server/http';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { BookingsServer } from '@wix/ambassador-bookings-server/http';
import { CheckoutServer } from '@wix/ambassador-checkout-server/http';
import { CouponsServer } from '@wix/ambassador-coupons-server/http';
import { ServiceType } from '@wix/bookings-uou-types';
import { mapCatalogResourceResponseToStaffMember } from '@wix/bookings-uou-mappers';
import { PaidPlans, Plan } from '@wix/ambassador-checkout-server/types';
import { CatalogData } from './types';
import { Service } from '../utils/mappers/service.mapper';
import { createSessionFromSlotAvailability } from './platformAdaters';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { mapBusinessResponseToBusinessInfo } from '../utils/mappers/businessInfo.mapper';
import { BusinessInfo } from '../types/types';
import { FormInfo } from '@wix/ambassador-bookings-server/types';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const CALENDAR_SERVER_URL = '_api/calendar-server';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const MEMBERS_SERVER_API = '_api/members/v1/members';
export const COUPONS_SERVER_URL = 'stores/v2/coupons/quer';

export class FormApi {
  private authorization: string;
  private wixSdkAdapter: WixOOISDKAdapter;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private calendarServer: ReturnType<typeof CalendarServer>;
  private checkoutServer: ReturnType<typeof CheckoutServer>;
  private couponsServer: ReturnType<typeof CouponsServer>;
  private membersServer: ReturnType<typeof MembersNgApi>;

  constructor({ wixSdkAdapter }: { wixSdkAdapter: WixOOISDKAdapter }) {
    this.wixSdkAdapter = wixSdkAdapter;
    const baseUrl = wixSdkAdapter.getServerBaseUrl();
    this.authorization = wixSdkAdapter.getInstance();
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.calendarServer = CalendarServer(`${baseUrl}${CALENDAR_SERVER_URL}`);
    this.checkoutServer = CheckoutServer(`${baseUrl}${CHECKOUT_SERVER_URL}`);
    this.couponsServer = CouponsServer(`${baseUrl}${COUPONS_SERVER_URL}`);
    this.membersServer = MembersNgApi(`${baseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
  }

  async getCatalogData({
    serviceId,
    resourceId,
  }: {
    serviceId: string;
    resourceId?: string;
  }): Promise<CatalogData> {
    const servicesCatalogService = this.catalogServer.Bulk();
    const bulkRequest: BulkRequest = this.createBulkRequest({
      serviceId,
      resourceId,
    });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const service: GetServiceResponse = catalogData.responseService!;
    const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );

    const activeFeatures: GetActiveFeaturesResponse =
      catalogData.responseBusiness!.activeFeatures!;
    const staffMembers = catalogData.responseResources!.resources!.map(
      mapCatalogResourceResponseToStaffMember,
    );

    return {
      service,
      businessInfo,
      activeFeatures,
      staffMembers,
    };
  }

  async getSlots({
    firstSessionStart,
    lastSessionEnd,
    scheduleId,
  }: {
    firstSessionStart: string;
    lastSessionEnd: string;
    scheduleId: string;
  }): Promise<ListSlotsResponse> {
    const calendarService = this.calendarServer.CalendarService();
    // @ts-expect-error
    const fields: string[] = null;
    // @ts-expect-error
    const fieldsets: string[] = null;
    const filter = {
      from: new Date(firstSessionStart).toISOString(),
      to: new Date(lastSessionEnd).toISOString(),
      scheduleIds: [scheduleId],
    };
    const request: ListSlotsRequest = {
      query: {
        fieldsets,
        fields,
        sort: [],
        filter: JSON.stringify(filter),
      },
    };

    const calendarServiceResponse = calendarService({
      Authorization: this.authorization,
    });
    return calendarServiceResponse.listSlots(request);
  }

  async getMemberDetails(id: string): Promise<Optional<Member>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return;
    }
    const membersService = this.membersServer.Members();
    const { member } = await membersService({
      Authorization: this.authorization,
    }).getMember({
      fieldSet: 'FULL',
      id,
    });

    return member;
  }

  async getPricingPlanDetails({
    serviceId,
    startTime,
  }: {
    serviceId: string;
    startTime: string;
  }): Promise<Optional<PaidPlans>> {
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.authorization })
      .checkoutOptions({
        createSession: {
          scheduleOwnerId: serviceId,
          start: {
            timestamp: startTime,
          },
        },
        paymentSelection: {
          numberOfParticipants: 1,
        },
      });
    return response.checkoutOptions?.paidPlans;
  }
  async isCouponsDefinedOnService(): Promise<boolean> {
    return true;

    // TODO: open this once has-coupon is open
    // const { hasCoupons } = await this.couponsServer
    //   .CouponsV2()({ Authorization: this.authorization })
    //   .hasCoupons({});

    // return hasCoupons;
  }

  async calculateCoupon(
    couponCode: string,
    slot: Slot,
    numberOfParticipants: number,
    rate: Rate,
  ) {
    const scheduleId = slot.scheduleId;
    const serviceId = slot.serviceId;
    const startTime = slot.startDate;
    const endTime = slot.endDate;
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.authorization })
      .checkoutOptions({
        scheduleId,
        couponCode,
        createSession: {
          rate,
          scheduleOwnerId: serviceId,

          start: {
            timestamp: startTime,
          },
          end: {
            timestamp: endTime,
          },
        },
        paymentSelection: {
          rateLabel: 'general',
          numberOfParticipants,
        },
      });
    return response.checkoutOptions?.payments;
  }

  async book({
    service,
    formInfo,
    slotAvailability,
    selectedPlan,
  }: {
    service: Service;
    formInfo: FormInfo;
    slotAvailability?: SlotAvailability;
    selectedPlan?: Plan;
  }) {
    const serviceTypeSpecificPayload = this.serviceTypeDependentRequestPayload(
      service,
      slotAvailability,
    );

    const response = await this.bookingsServer
      .Bookings()({ Authorization: this.authorization })
      .book({
        ...serviceTypeSpecificPayload,
        formInfo,
        planSelection: selectedPlan?.paidPlan,
      });

    return response;
  }

  private serviceTypeDependentRequestPayload(
    service: Service,
    slotAvailability?: SlotAvailability,
  ) {
    switch (service.type) {
      case ServiceType.INDIVIDUAL:
        return {
          createSession: createSessionFromSlotAvailability(slotAvailability!),
        };
      case ServiceType.GROUP:
        return {
          bySessionId: {
            sessionId: slotAvailability!.slot!.sessionId,
          },
        };
      case ServiceType.COURSE:
        return {
          scheduleId: service.scheduleId,
        };
    }
  }

  private createBulkRequest({
    serviceId,
    resourceId,
  }: {
    serviceId: string;
    resourceId?: string;
  }): BulkRequest {
    const filterByResourceType = {
      'resource.tags': { $hasSome: ['staff'] },
    };
    const filterById = {
      'resource.id': resourceId,
    };
    const filter = resourceId ? filterById : filterByResourceType;

    return {
      requestService: {
        id: serviceId,
        fields: [],
      },
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestListResources: {
        includeDeleted: true,
        query: {
          filter: JSON.stringify(filter),
          fields: ['resource.id', 'resource.name'],
          fieldsets: [],
          paging: {
            limit: 1000,
          },
          sort: [],
        },
      },
    };
  }
}
