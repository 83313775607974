import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import {
  BookingDetailsViewModel,
  createBookingDetailsViewModel,
} from './BookingDetailsViewModel/bookingDetailsViewModel';
import { FormView } from '@wix/forms-ui/types';
import {
  PaymentViewModel,
  createPaymentViewModel,
} from './PaymentViewModel/paymentViewModel';

export type FormViewModel = {
  bookingDetailsViewModel: BookingDetailsViewModel;
  paymentViewModel: PaymentViewModel;
  formSchemaViewModel: FormView;
};

export const createFormViewModel = (
  viewModelFactoryParams: ViewModelFactoryParams<FormState, FormContext>,
): FormViewModel => ({
  bookingDetailsViewModel: createBookingDetailsViewModel(
    viewModelFactoryParams,
  ),
  paymentViewModel: createPaymentViewModel(viewModelFactoryParams),
  formSchemaViewModel: viewModelFactoryParams.state.service.form,
});
