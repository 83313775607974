import { CatalogData } from '../../api/types';
import { TFunction } from '../../components/BookingsForm/controller';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApi } from '../../api/FormApi';

export const DEFAULT_LOCALE = 'en-US';

export type FormContext = {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  dateAndTimeFormatter: DateTimeFormatter;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
  hasCoupon: boolean;
};

export const createFormContext = ({
  catalogData,
  t,
  settings,
  wixSdkAdapter,
  formApi,
  hasCoupon,
}: {
  catalogData: CatalogData;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
  hasCoupon: boolean;
}): FormContext => {
  const locale =
    catalogData.businessInfo?.dateRegionalSettingsLocale ?? DEFAULT_LOCALE;
  const dateAndTimeFormatter = new DateTimeFormatter(locale);

  return {
    t,
    settings,
    dateAndTimeFormatter,
    wixSdkAdapter,
    formApi,
    hasCoupon,
  };
};
