import {
  FormViewField,
  SubmissionResponse,
  SubmissionValue,
} from '@wix/forms-ui/types';
import {
  ContactDetails,
  FormInfo,
} from '@wix/ambassador-bookings-server/types';
import { Service } from './service.mapper';
import { BookingsKeyMapping } from './service-form-field.mapper';

export type OnSubmit = (submission: SubmissionResponse) => void;

export enum RateLabels {
  GENERAL = 'general',
}

export enum BookingRequestKeyMappings {
  ADDRESS = 'address',
  CONTACT_ID = 'contactId',
  COUNTRY_CODE = 'countryCode',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FULL_ADDRESS = 'fullAddress',
  LASTNAME = 'lastName',
  PHONE = 'phone',
  TIMEZONE = 'timeZone',
  NO_OF_PARTICIPANTS = 'noOfParticipants',
}

export function mapSubmissionToFormInfo(
  submission: {
    [k: string]: SubmissionValue;
  },
  service: Service,
): FormInfo {
  const { form } = service;
  const formInfo: FormInfo = createBaseFormInfo();
  form!.fields!.forEach((field) => {
    const value = submission[field.externalId!];
    if (value) {
      if (isNoOfParticipants(field)) {
        setNoOfParticipants(formInfo, Number(value));
      } else if (isContactInfoField(field)) {
        appendContactInfoField(formInfo, field, value);
      } else {
        appendCustomField(formInfo, field, value);
      }
    }
  });
  return formInfo;
}

function createBaseFormInfo(): FormInfo {
  return {
    customFormFields: {},
    contactDetails: {},
    paymentSelection: [
      {
        numberOfParticipants: 1,
        rateLabel: 'general',
      },
    ],
  };
}

function isContactInfoField(field: FormViewField) {
  return !!field?.renderInfo?.metadata?.bookingsKeyMapping;
}

function appendContactInfoField(
  formInfo: FormInfo,
  field: FormViewField,
  value: any,
) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  formInfo!.contactDetails![bookingsKeyMapping.key as keyof ContactDetails] =
    mapFormValueToContactInfoProperty?.[bookingsKeyMapping.key]?.(value) ??
    value;
}

function isNoOfParticipants(field: FormViewField) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return (
    bookingsKeyMapping?.key === BookingRequestKeyMappings.NO_OF_PARTICIPANTS
  );
}

function setNoOfParticipants(formInfo: FormInfo, noOfParticipants: number) {
  formInfo!.paymentSelection!.find(
    (payment) => payment.rateLabel === RateLabels.GENERAL,
  )!.numberOfParticipants = noOfParticipants;
}

function appendCustomField(
  formInfo: FormInfo,
  field: FormViewField,
  value: any,
) {
  formInfo!.customFormFields![field.externalId!] = value;
}

const filterUndefined = (value: any) => !!value;

export const mapFormValueToContactInfoProperty: {
  [key in BookingRequestKeyMappings]?: (value: any) => any;
} = {
  phone: (value: any) => value.phone,
  fullAddress: (value: any) => {
    return {
      subdivision: value.state,
      city: value.city,
      addressLine: value.streetLine1,
      addressLine2: value.streetLine2,
      formattedAddress: [
        value.streetLine1,
        value.streetLine2,
        value.city,
        value.state,
      ]
        .filter(filterUndefined)
        .join(', '),
    };
  },
};
