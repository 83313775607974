import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CatalogData } from '../types';
import { dummyServiceResponse } from '../../../__tests__/mocks/ambassador/dummyServiceResponse';
import { mockBusinessInfo } from '../../../__tests__/mocks/mockBusinessInfo';

export function createDummyCatalogData(
  flowAPI: ControllerFlowAPI,
): CatalogData {
  const { t, config } = flowAPI.translations;
  const service = dummyServiceResponse();

  const businessInfo = mockBusinessInfo({
    language: config.language,
    currency: t('dummy-content.business.currency'),
    timeZone: t('dummy-content.business.timeZone'),
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: t('dummy-content.business.regionalSettingsLocale'),
  });

  const activeFeatures = {
    applicableForCourse: true,
    bookingsStaffLimit: 1,
    applicableForServiceList: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForSmsReminders: true,
  };
  const staffMembers = [
    {
      id: 'staff-member-1',
      name: 'name',
    },
  ];

  return {
    service,
    businessInfo,
    activeFeatures,
    staffMembers,
  };
}
