import { ServiceType } from '@wix/bookings-uou-types';
import {
  GetServiceResponse,
  ScheduleStatus,
} from '@wix/ambassador-services-catalog-server/http';
import { LocationLocationType } from '@wix/ambassador-bookings-server/http';

export const dummyServiceResponse = () => {
  return {
    schedules: [
      {
        tags: [ServiceType.GROUP],
        status: ScheduleStatus.CREATED,
        id: 'dummy-schedule-id',
        availability: {
          locations: [
            {
              address: 'somewhere',
              locationType: LocationLocationType.OWNER_CUSTOM,
            },
          ],
        },
        conferenceProvider: {
          providerId: 'some-provider-id',
        },
      },
    ],
    resources: [
      {
        id: '1',
        name: 'David',
      },
    ],
  } as GetServiceResponse;
};
