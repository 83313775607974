import { BusinessInfo } from '../../src/types/types';

export const mockBusinessInfo = (
  overrides: Partial<BusinessInfo>,
): BusinessInfo => {
  const defaultBusinessInfo = {
    regionalSettingsLocale: 'en',
    dateRegionalSettingsLocale: 'en-US',
    isSMSReminderEnabled: false,
  };
  return {
    ...defaultBusinessInfo,
    ...overrides,
  };
};
